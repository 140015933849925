import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import * as styles from './ConfirmTelecubeReservation.scss';
import { useLiff } from '../../../containers/LiffContainer';

const Component: FC = () => {
  const { getAccessToken } = useLiff()
  const history = useHistory()
  const [buttonDisabled, setButtonDisabled] = React.useState(false)

  // テレキューブ予約申し込み
  const applyTelecubeReservation = async () => {
    try {
      await axios
        .post(
          `${process.env.API_BASE_URL}/api/telecube-reservation/apply`,
          {},
          {
            headers: { Authorization: 'Bearer ' + getAccessToken() },
          }
        )
      liff.closeWindow();
    } catch (err: any) {
      if (err?.response != 200) {
        alert(
          "エラーが発生しました。\n再度ページをお開きいただくか、LINE上でお問い合わせください。"
        )
      }

      setButtonDisabled(false)
    }
  }

  const openTelecubeLp = () => {
    liff.openWindow({
      url: 'https://brandear.jp/bell/telecube'
    })
  };

  return (
    <Container>
      <Helmet>
        <title>手続き前確認</title>
      </Helmet>

      <div className={styles.wrap}>
        <div className={styles.usageCautionContainer}>
          <p>テレキューブのご利用方法は</p>
          <p>
            <a href="#" className={styles.telecubeLp} onClick={openTelecubeLp}>こちら</a>から事前にご確認ください
          </p>
        </div>

        <div className={styles.proceedProcessContainer}>
          <p>テレキューブでの査定を</p>
          <p>予約いたします</p>
          <p>手続きを進めますか？</p>
        </div>

        <div className={styles.buttons}>
          <Button
            className={`pb-3 pt-3 ${styles.yesBtn}`}
            type="button"
            variant="light"
            onClick={() => {
              setButtonDisabled(true)
              applyTelecubeReservation()
            }}
            disabled={buttonDisabled}
          >
            次へ進む
          </Button>
          <Button
            className={`${styles.backBtn} mt-5`}
            type="button"
            variant="link"
            onClick={
              // LINEメッセージ上から遷移した場合は戻り先が無いのでliffを閉じる
              history.action === 'POP'
                ? liff.closeWindow
                : history.goBack
            }
          >
            戻る
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Component;
