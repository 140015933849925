import * as React from 'react';
import axios from 'axios';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { useLiff } from '../containers/LiffContainer';

const Component: React.FC = () => {
  const { getAccessToken, isInClient } = useLiff();
  const history = useHistory();

  // 流入元を登録するfunction
  const patchAcquisitionChannel = async (source: string) => {
    await axios.patch(
      `${process.env.API_BASE_URL}/api/users-acquisition-channel`,
      {
        source,
      },
      { headers: { Authorization: 'Bearer ' + getAccessToken() } }
    );
  };

  const moveToChatScreenOrRedirectToTop = () => {
    if (isInClient()) {
      location.href = `https://line.me/R/ti/p/${process.env.BOT_BASIC_ID}`;
    } else {
      history.push('/');
    }
  };

  // GETパラメータにsourceが指定されている場合、流入元を登録するfunctionを呼び出す
  React.useEffect(() => {
    const params = qs.parse(location.search);
    if (params.source) {
      patchAcquisitionChannel(params.source as string).then(() => {
        moveToChatScreenOrRedirectToTop();
      });
    } else {
      moveToChatScreenOrRedirectToTop();
    }
  }, []);

  return <></>;
};

export default Component;
