import * as React from 'react';
import { Container, Row, Table, Image, Spinner, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import axios from 'axios';

import { useLiff } from '../../../containers/LiffContainer';

import SelectedPurchaseItemEntry from '../../molecules/SelectedPurchaseItemEntry/SelectedPurchaseItemEntry';
import DesiredAttendant from '../../molecules/DesiredAttendant/DesiredAttendant';

import { CertainPeriodPurchaseSchedules, PurchaseRequest, WorkableAttendant } from '../../../types/types';

import * as styles from './SelectPurchaseSchedulePage.scss';

import stepPurchaseSchedule from '../../../assets/images/steps/purchaseSchedule.png';
import {
  MAX_QTY_FOR_30_MINUTES_FRAME,
  MAX_QTY_FOR_60_MINUTES_FRAME,
  MAX_QTY_FOR_90_MINUTES_FRAME,
  MAX_QTY_FOR_120_MINUTES_FRAME
} from '../../../enums/SelectablePurchaseItemsQty';

const Component: React.FC = () => {
  const { getAccessToken } = useLiff();
  const history = useHistory();
  const { state } = useLocation<{
    selectedQty?: number,
    remarks?: (string | null)[],
    defaultIsDesiredSameAttendant?: boolean,
    desiredAttendantId?: number,
  }>();

  const params = qs.parse(location.search);
  const renderFlgRef = React.useRef(false);

  const [purchaseItemsQty, setPurchaseItemsQty] = React.useState<number>(MAX_QTY_FOR_60_MINUTES_FRAME)
  const [purchaseSchedules, setPurchaseSchedules] = React.useState<CertainPeriodPurchaseSchedules>();
  const [beforeRequestId, setBeforeRequestId] = React.useState<string>();
  const [beforeAssessmentKpiId, setBeforeAssessmentKpiId] = React.useState<string | null>(null);
  const [workableAttendants, setWorkableAttendants] = React.useState<WorkableAttendant[]>();
  const [yearMonthColQty, setYearMonthColQty] = React.useState<{[YearMonthString: string]: number }>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isDesiredSameAttendant, setIsDesiredSameAttendant] = React.useState<boolean>(state?.defaultIsDesiredSameAttendant || false);
  const [desiredAttendantId, setDesiredAttendantId] = React.useState<number | null>(state?.desiredAttendantId || Number(params.desiredAttendantId) ||null);
  const [isRepeater, setIsRepeater] = React.useState<boolean>(false);

  const isPurchaseItemTwoOrLess = (purchaseItemsQty: number) => {
    return purchaseItemsQty === MAX_QTY_FOR_30_MINUTES_FRAME; // purchaseItemsQtyがNaNの場合はfalseになる
  }

  const isPurchaseItemNine = (purchaseItemsQty: number) => {
    return purchaseItemsQty === MAX_QTY_FOR_90_MINUTES_FRAME;
  }

  const isPurchaseItemTwelve = (purchaseItemsQty: number) => {
    return purchaseItemsQty === MAX_QTY_FOR_120_MINUTES_FRAME;
  }

  // APIリクエスト時のエラーハンドリング
  const handleApiError = (): void => {
    alert('エラーが発生しました。再度ページをお開きいただくか、LINE上でお問い合わせください。')
    liff.closeWindow()
  }

  // 変更前の買取リクエストが変更対象のものか検証
  const verifyValidReservation = async (purchaseRequestId: string) => {
    try {
      const { data: purchaseRequest } = await axios.get<PurchaseRequest>(
        `${process.env.API_BASE_URL}/api/purchase-request/${purchaseRequestId}`,
        {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
        }
      );

      // 予約データ(purchase_request)が空、または 未予約であればalert表示してLIFFを閉じる
      if (
        Object.keys(purchaseRequest).length === 0
        || !purchaseRequest.isReserved
      ) {
        alert('予約データが不正です。変更前のご予約が変更可能か確認してください。')
        liff.closeWindow()
      }
    } catch {
      alert('エラーが発生しました。お手数ですが再度お試しください。')
      liff.closeWindow()
    }
  }

  //買取スケジュール(maxdayforcalendar日分)を取得
  const getPurchaseSchedules = (
      isPurchaseItemTwoOrLess: boolean,
      isPurchaseItemNine: boolean,
      isPurchaseItemTwelve: boolean,
  ) => (
    axios
      .get<CertainPeriodPurchaseSchedules>
      (
        `${process.env.API_BASE_URL}/api/online-purchase-schedules/certain-period-schedules`,
        {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
          params: {
            isPurchaseItemTwoOrLess: Number(isPurchaseItemTwoOrLess),
            isPurchaseItemNine: Number(isPurchaseItemNine),
            isPurchaseItemTwelve: Number(isPurchaseItemTwelve)
          }
        }
      )
  )

  //接客員の稼働可能なスケジュール(maxdayforcalendar日分)を取得
  const getWorkableAttendantSchedules = (
    isPurchaseItemTwoOrLess: boolean,
    isPurchaseItemNine: boolean,
    isPurchaseItemTwelve: boolean,
    attendantId: number
  ) => (
    axios
      .get<CertainPeriodPurchaseSchedules>
      (
        `${process.env.API_BASE_URL}/api/online-purchase-schedules/certain-period-attendant-schedules`,
        {
          params: {
            isPurchaseItemTwoOrLess: Number(isPurchaseItemTwoOrLess),
            isPurchaseItemNine: Number(isPurchaseItemNine),
            isPurchaseItemTwelve: Number(isPurchaseItemTwelve),
            attendantId: attendantId
          }
        }
      )
  )

  // 稼働可能な接客員一覧を取得
  const getWorkableAttendants = () => (
    axios.get<WorkableAttendant[]>
      (
        `${process.env.API_BASE_URL}/api/attendants/workable-attendants`,
      )
  )

  // 予約者がリピーターか判定
  const getIsRepeater = () => (
    axios
      .get<boolean>
      (
        `${process.env.API_BASE_URL}/api/users/account/is-repeater`,
        {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
        }
      )
  )


  // 予約変更初期処理として仮予約情報を作成or更新し、変更前買取持込予定品をコピー
  const postPurchaseRequestAndCopyItems = async (
    scheduleId: number,
    beforeRequestId: string,
    beforeAssessmentKpiId: string|null,
    desiredAttendantId: number|null
  ) => {
    setIsLoading(true)
    try {
      await axios.post(
        `${process.env.API_BASE_URL}/api/purchase-request/copy-item`,
        {
          scheduleId,
          beforeRequestId,
          beforeAssessmentKpiId,
          isDesiredSameAttendant,
          desiredAttendantId,
        },
        { headers: { Authorization: 'Bearer ' + getAccessToken() } }
      );
    } catch {
      alert('エラーが発生しました。お手数ですが、最初からやり直してください。');
    } finally {
      liff.closeWindow();
    }
  }

  /**
   * 予約可能枠数によってアイコンを切り替え表示
   * @param bookableCount
   */
  const displayIconByBookableCount = (bookableCount: number): JSX.Element => {
    switch (true) {
      case bookableCount === 0:
        return <span>×</span>
      case bookableCount === 1:
        return (
          desiredAttendantId
            ? <span className={styles.thinIcon}>○</span>
            : <span className={`${styles.thinIcon} ${styles.triangle}`}>△</span>
        )
      case bookableCount >= 2:
        return <span className={styles.thinIcon}>○</span>
      default:
        return <span>×</span>
    }
  }

  /**
   * 仮予約情報を作成or更新
   * @param scheduleId
   */
  const createOrUpdateTmpPurchaseRequest = async (scheduleId: number, desiredAttendantId: number|null) => {
    const remarks = state?.remarks
    if (!remarks) {
      return alert('持込予定品情報が存在しません。\n前ページから再度の入力をお願いします。')
    }

    // 少々処理に時間がかかるので、ローディングを挟む
    setIsLoading(true)

    try {
      await axios.put(
        `${process.env.API_BASE_URL}/api/purchase-request`,
        {
          scheduleId,
          desiredAttendantId,
        },
        { headers: { Authorization: 'Bearer ' + getAccessToken() } }
      );

      await axios.put(
        `${process.env.API_BASE_URL}/api/purchase-request/item`,
        {
          remarks,
          isDesiredSameAttendant,
          desiredAttendantId,
        },
        {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
        }
      );
    } catch (err) {
      alert('エラーが発生しました。お手数ですが、最初からやり直してください。');
    } finally {
      liff.closeWindow();
    }
  }

  const onScheduleClick = (scheduleId: number) => {
    beforeRequestId
      ? postPurchaseRequestAndCopyItems(scheduleId, beforeRequestId, beforeAssessmentKpiId, desiredAttendantId) // 予約変更処理
      : createOrUpdateTmpPurchaseRequest(scheduleId, desiredAttendantId); // 通常フロー
  }

  React.useEffect(() => {

    const initItem = async () => {
      // 予約変更リクエストの場合は変更前の予約が有効か検証
      const beforeRequestIdParam = params.beforeRequestId as string | undefined
      if (beforeRequestIdParam) {
        verifyValidReservation(beforeRequestIdParam)
        setBeforeRequestId(beforeRequestIdParam)
      }
      const beforeAssessmentKpiIdParam = params.beforeAssessmentKpiId as string | undefined
      if (beforeAssessmentKpiIdParam) {
        setBeforeAssessmentKpiId(beforeAssessmentKpiIdParam)
      }

      // 予約日時変更の場合はremarksが渡されないためクエリパラメータから判断
      const purchaseItemsQtyData = Number(state?.remarks?.length || params.purchaseItemsQty);
      setPurchaseItemsQty(purchaseItemsQtyData)

      try {
        const isRepeater = getIsRepeater();
        const workableAttendantsData = getWorkableAttendants();

        if (desiredAttendantId) {
          // 接客員のスケジュールを取得
          const workableAttendantSchedulesData = getWorkableAttendantSchedules(
            isPurchaseItemTwoOrLess(purchaseItemsQty), isPurchaseItemNine(purchaseItemsQty), isPurchaseItemTwelve(purchaseItemsQty), desiredAttendantId
          );
          setIsRepeater((await isRepeater).data)
          setWorkableAttendants((await workableAttendantsData).data);
          setPurchaseSchedules((await workableAttendantSchedulesData).data)

          setIsLoading(false)
          return
        }

        const purchaseSchedulesData = getPurchaseSchedules(
            isPurchaseItemTwoOrLess(purchaseItemsQtyData), isPurchaseItemNine(purchaseItemsQtyData), isPurchaseItemTwelve(purchaseItemsQtyData)
        );
        setIsRepeater((await isRepeater).data)
        setWorkableAttendants((await workableAttendantsData).data);
        setPurchaseSchedules((await purchaseSchedulesData).data)
      } catch {
        handleApiError()
      }

      setIsLoading(false)
    };

    initItem();
  }, []);


  // カレンダーの年月更新時、カラムが分かれるようにする
  React.useEffect(() => {
    if (!purchaseSchedules) {
      return
    }

    const dateStrings = Object.keys(purchaseSchedules)
    const convertDateObj = (dateString: string): Date => new Date(dateString.slice(0, -3)) // 末尾3文字の曜日「（水）」を削除することでDateオブジェクトとして扱える
    const formatYearMonth = (dateString: string): string => `${convertDateObj(dateString).getFullYear()}年${convertDateObj(dateString).getMonth() + 1}月` // ex) 2022年3月
    const formatMonth = (dateString: string): string => `${convertDateObj(dateString).getMonth() + 1}月` // ex) 3月 （getMonthでは0~11の範囲なので+1）
    const formatDay = (dateString: string): number => convertDateObj(dateString).getDate() // ex) 1

    let yearMonthStr = formatYearMonth(dateStrings[0])
    const originYearMonthStr = formatYearMonth(dateStrings[0])

    // {年月:colspanの値}となるオブジェクトを定義
    let adjustYearMonthColQty = { [yearMonthStr]: dateStrings.length }
    dateStrings.forEach((dateString, i) => {
      const targetYearMonth = formatYearMonth(dateString)

      // 年月が変化するとき、翌月となる値を該当オブジェクトに追加する
      if (yearMonthStr !== targetYearMonth) {
        adjustYearMonthColQty[yearMonthStr] = i
        adjustYearMonthColQty[targetYearMonth] = dateStrings.length - i
        yearMonthStr = targetYearMonth
      }
    })
    // APIから取得した最後のスケジュールが月初日(1日)、または最初のスケジュールが月末日(28~31日)の場合、月のみを表示する
    // 元の'{年月:colspanの値}'プロパティを削除後、{月:colspanの値}'の新たなプロパティを追加
    let onlyMonth : string;
    switch(true) {
      case formatDay(dateStrings.slice(-1)[0]) === 1 :
        delete adjustYearMonthColQty[yearMonthStr]
        onlyMonth = formatMonth(dateStrings.slice(-1)[0])
        adjustYearMonthColQty[onlyMonth] = adjustYearMonthColQty[yearMonthStr]
        break;
      case [28, 29, 30, 31].includes(formatDay(dateStrings[0])) && formatDay(dateStrings[1]) === 1:
        delete adjustYearMonthColQty[originYearMonthStr]
        onlyMonth = formatMonth(dateStrings[0])
        let onlyMonthAndColQty = { [onlyMonth] : adjustYearMonthColQty[originYearMonthStr] }
        adjustYearMonthColQty = Object.assign(onlyMonthAndColQty, adjustYearMonthColQty);
        break;
    }

    setYearMonthColQty(adjustYearMonthColQty)
  },[purchaseSchedules])

  React.useEffect(() => {
    // 初回はレンダリングを行わないように制御
    if(!renderFlgRef.current) {
      renderFlgRef.current = true
      return
    }

    ;(async () => {
      setIsLoading(true)

      try {
        // 接客員が選択されていなければ通常スケジュールを取得
        if (!desiredAttendantId) {
          const purchaseSchedulesData = getPurchaseSchedules(
            isPurchaseItemTwoOrLess(purchaseItemsQty), isPurchaseItemNine(purchaseItemsQty), isPurchaseItemTwelve(purchaseItemsQty)
          );
          setPurchaseSchedules((await purchaseSchedulesData).data)
          setIsLoading(false)
          return;
        }

        // 接客員のスケジュールを取得
        const workableAttendantSchedulesData = getWorkableAttendantSchedules(
          isPurchaseItemTwoOrLess(purchaseItemsQty), isPurchaseItemNine(purchaseItemsQty), isPurchaseItemTwelve(purchaseItemsQty), desiredAttendantId
        );
        setPurchaseSchedules((await workableAttendantSchedulesData).data)
      } catch {
        alert('エラーが発生しました。')
      }

      setIsLoading(false)
    })()
  }, [desiredAttendantId])

  return (
    <Container>
      <Helmet>
        <title>日時選択</title>
      </Helmet>
      <Row className={`justify-content-center`}>
        <Image className={styles.headerStep} src={stepPurchaseSchedule} rounded />
      </Row>
      {isLoading && (
        <div className={styles.screenOverlay}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      <SelectedPurchaseItemEntry
        // 予約日時変更の場合はselectedQtyが渡されないためクエリパラメータからアイテム数を取得
        selectedQty={state?.selectedQty || Number(params.purchaseItemsQty)}
      />

      {isRepeater && (
        <>
          <div className={styles.selectFormWrap}>
            <h5 className={`${styles.boldText} mt-5 mb-3`}>
              ご希望の接客員<sup className={`${styles.textLightRed} ${styles.supText}`}>※必須</sup>
            </h5>
            <p className="mb-2">
              ※以前ご利用いただいたお客様のみ接客員の指名が可能です。<br />※接客員の勤務状況によりご希望に添えない場合がございます。
            </p>
            <fieldset className={styles.radioFieldset}>
              <input
                id="not-desired"
                className={styles.radioInput}
                type="radio"
                name="attendant-select"
                value={0}
                checked={!desiredAttendantId && !isDesiredSameAttendant}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.persist()
                  setIsDesiredSameAttendant(Boolean(Number(e.target.value))) // 文字列の0をfalsyに扱うためにNumber()を経由
                  setDesiredAttendantId(null)
                }}
              />
              <label
                className={`
                  ${styles.radioLabel}
                  ${!desiredAttendantId && !isDesiredSameAttendant ? styles.checked : ''}
                `}
                htmlFor="not-desired"
              >
                <i className="fa fa-check fa-1 d-none"></i>
                希望なし
              </label>
              {workableAttendants && (
                <>
                  {workableAttendants.map((workableAttendant, index) => {
                    const workableAttendantId = workableAttendant.attendantId

                    return (
                      <div key={index} className='d-inline'>
                        <input
                          id={"attendant_"+workableAttendantId}
                          className={styles.radioInput}
                          type="radio"
                          name="attendant-select"
                          value={workableAttendantId}
                          checked={workableAttendantId === desiredAttendantId}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            e.persist()
                            setIsDesiredSameAttendant(false),
                            setDesiredAttendantId(Number(e.target.value))
                          }}
                        />
                        <label
                          className={`
                            ${styles.radioLabel}
                            ${workableAttendantId === desiredAttendantId ? styles.checked : ''}
                          `}
                          htmlFor={"attendant_"+workableAttendantId}
                        >
                          <i className="fa fa-check fa-1 d-none"></i>
                          {workableAttendant.attendantName}
                        </label>
                      </div>
                    )
                  })}
                </>
              )}
            </fieldset>
          </div>
          {workableAttendants && (
            workableAttendants
              .filter((workableAttendant) => {
                return workableAttendant.attendantId === desiredAttendantId
              })
              .map((workableAttendant, index) => {
                return (
                  <DesiredAttendant
                    key={index}
                    workableAttendant={workableAttendant}/>
                )
              })
          )}
        </>
      )}

      <div className={`d-flex align-items-center justify-content-between mb-2 ${!desiredAttendantId ? "mt-5" : ""}`}>
        <div>
          {!desiredAttendantId &&
            <h5 className={`${styles.boldText}`}>
              ご希望の日時<sup className={`${styles.textSoftRed} ${styles.supText}`}>※必須</sup>
            </h5>
          }
          {isPurchaseItemTwoOrLess(purchaseItemsQty) && <p className={`mt-1 mb-2 small ${styles.attentionFor30Frame}`}>30分枠のご予約をお取りします。</p>}
        </div>
      </div>

      {purchaseSchedules && yearMonthColQty && (
        <>
          <div className={styles.scrollTable}>
            <Table>
              <thead className="sticky-top bg-white">
              <tr>
                <th rowSpan={2} className={styles.blankSpace}></th>
                {
                  Object.keys(yearMonthColQty).map((yearMonthStr, i) => {
                    const colQty = yearMonthColQty[yearMonthStr]
                    return (
                        <th colSpan={colQty} className={styles.scheduleMonthText} key={i}>
                          {yearMonthStr}
                        </th>
                    )
                  })
                }
              </tr>
              <tr>
                {/* maxdayforcalendar日分の日付を初期表示 */}
                {Object.keys(purchaseSchedules).map((dateString, index) => {
                  const isSaturday = dateString.indexOf('土') !== -1
                  const isSunday = dateString.indexOf('日') !== -1

                  return (
                      <th
                          key={index}
                          className={` ${styles.scheduleDateTextContainer} text-center`}
                          style={{
                            color:
                                isSaturday
                                    ? '#0081C0' // 青
                                    : isSunday
                                        ? '#E35450' // 赤
                                        : ''
                          }}
                      >
                        {/* 日付と曜日の間に改行を入れる */}
                        { dateString.replace('(', '\n').split('\n').map((dayStr: string, index) => {
                              // 日付と曜日のみ表示
                              const day = dayStr.slice(-2).replace('/', '').replace(')', '');
                              return <React.Fragment key={index}>{day}<br /></React.Fragment>
                            }
                        )}
                      </th>
                  )
                })
                }
              </tr>
              </thead>
              <tbody>
                <tr>
                  {/* 時間表示（オブジェクトの最初の要素から時間のみ抽出） */}
                  <th className={styles.scheduleTimeTextContainer}>
                    {purchaseSchedules[Object.keys(purchaseSchedules)[0]].map(({ time }) =>
                      (
                        <p key={time} className={`${styles.scheduleTimeText}`}>
                          {time}
                        </p>
                      )
                    )}
                  </th>

                  {Object.values(purchaseSchedules).map((schedules, i) =>
                    <th className={styles.scheduleDateTitle} key={i}>
                      {schedules.map(({ onlinePurchaseScheduleId, bookableCount}, index) =>
                        bookableCount > 0 && onlinePurchaseScheduleId !== 0
                          ?
                            <button
                              key={index}
                              type="button"
                              className={styles.itemButton}
                              onClick={() => onScheduleClick(onlinePurchaseScheduleId)}
                            >
                              {displayIconByBookableCount(bookableCount)}
                            </button>
                          :
                            <p className={`${styles.itemButton} ${styles.notBookable}`} key={index}>
                              {displayIconByBookableCount(0)}
                            </p>
                      )}
                    </th>
                  )}
                </tr>
              </tbody>
            </Table>
          </div>

          <div className={`${styles.usageGuide} ml-1 mt-1`}>
            <p className="mr-2">○空きあり</p>
            {!desiredAttendantId && <p className="mr-2">△残り1枠</p>}
            <p>×空きなし</p>
          </div>

          <div className="text-center my-4">
            <Button
              className={styles.backBtn}
              type="button"
              variant="link"
              onClick={
                // 戻り先が無い場合はliffを閉じる
                history.action === 'POP'
                  ? liff.closeWindow
                  : history.goBack
              }
            >
              戻る
            </Button>
          </div>
        </>
      )}
  </Container>
  );
};

export default Component;
