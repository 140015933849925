import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useLiff } from '../../../containers/LiffContainer';
import * as styles from './NotFound.scss';

const Component: React.FC = () => {
  const history = useHistory();
  const { isInClient, closeWindow } = useLiff();

  return (
    <div className={`${styles.container}`}>
      <div className={styles.headLogo} />

      <div className={styles.bodyWrapper}>
        <div className={styles.body}>
          <p className={`text-center text-white ${styles.noticeWording}`}>
            404 not found
          </p>
          <p className={`text-center text-white ${styles.noticeWordingJa}`}>
            お探しのページは見つかりません。
          </p>
          <a
            className={`${styles.button} ${styles.backButton}`}
            onClick={() => {
              if (isInClient()) {
                closeWindow();
              } else {
                history.push('/');
              }
            }}
          >
            メニューへ戻る
          </a>
        </div>
      </div>

      <div className={styles.bottomWrapper}>
        <div className={styles.buildings} />
        <div className={styles.sea} />
        <div className={styles.kibotosShip} />
      </div>
    </div>
  );
};

export default Component;
