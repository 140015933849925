import * as React from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';

// Debug用のrich menuと同じnavigation
const DebugMenu: React.FC = () => {
  const history = useHistory();

  // querystringにpathの指定がある場合は、そのpathに遷移する
  // liff.login()して返ってきた際に、遷移先を指定するためにpathの指定を行っている(ref.: PrivateRoute component)
  const params: { path?: string; [key: string]: any } = qs.parse(
    location.search
  );
  if (params.path) {
    let { path } = params;
    delete params.path;
    if (path.startsWith('/liff')) {
      path = path.replace(/^\/liff/, '');
    }
    history.push(`${path}?${qs.stringify(params)}`);

    return <></>;
  }

  return <></>;
};

export default DebugMenu;
