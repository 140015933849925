import * as React from 'react';
import { Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import * as styles from './PurchaseItemTextForm.scss';
import { useLiff } from '../../../containers/LiffContainer';
import checkBox from '../../../assets/images/checkBox.png';
import checkedBox from '../../../assets/images/checkedBox.png';
import { SelectedItem } from '../../../types/types';

type Props = {
  selectedItems: SelectedItem[]
  scheduleId: string
};

const Component: React.FC<Props> = ({ selectedItems, scheduleId }) => {
  const { getAccessToken } = useLiff();
  const history = useHistory();

  const [covenantChecked, setCovenantChecked] = React.useState(false);
  const [submitDisabled, setSubmitDisabled] = React.useState(false)
  const [remarks, setRemarks] = React.useState<string>(() => {
    if (selectedItems.length === 0) {
      return ''
    }

    return selectedItems
      .map(({ remarks }) => {
        // nullを除去
        const defaultRemarks = remarks ? remarks : ''

        return `${defaultRemarks}`
      })
      .filter((item) => !!item)
      .join('\n')
  }
  )

  React.useEffect(() => {
    setSubmitDisabled(!covenantChecked);
  }, [covenantChecked]);

  const openCovenantUrl = () => {
    liff.openWindow({
      url: 'https://brandear.jp/ct/kiyaku_riyou?pid_bellkiyaku#tab3'
    })
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitDisabled(true)

    // 予約情報を作成or更新
    try {
      await axios.put(
        `${process.env.API_BASE_URL}/api/purchase-request`,
        {
          scheduleId,
        },
        { headers: { Authorization: 'Bearer ' + getAccessToken() } }
      );

      // 個数情報を保持するための処理
      const remarksArray = selectedItems.length === 0
        ? [remarks]
        : [remarks].concat(Array(selectedItems.length - 1).fill(null));

      await axios.put(
        `${process.env.API_BASE_URL}/api/purchase-request/item`,
        {
          remarks: remarksArray,
        },
        {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
        }
      );

      liff.closeWindow()
    } catch (err) {
      alert('予約手続きに失敗しました。\nお手数ですが時間を置いて再度お試しください。')
      setSubmitDisabled(false) // 失敗した場合は再度押下可能にする
    }
  };

  return (
    <Form className="flex-fill px-3 pt-4" onSubmit={handleSubmit} noValidate>
      <Form.Group controlId="h" className="mb-1">
        <Form.Label className="font-weight-bold">ブランド・ジャンル・型番等</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="商品情報がお分かりになる方はご記載をお願いします。&#13;記載例：ルイヴィトン・バッグ・SP0933"
          className={`${styles.formText}`}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
            e.persist()
            setRemarks(e.target.value)
          }}
          defaultValue={remarks}
        />
      </Form.Group>
      <div className={`${styles.supplementaryExplanation}`}>
        <p>※型番など商品情報を事前にお知らせいただいた方は速やかに査定が行えます。</p>
      </div>

      <div>
        <img
          className={styles.covenantCheckBox}
          src={covenantChecked ? checkedBox : checkBox}
          onClick={() => setCovenantChecked(!covenantChecked)}
        />
        <a href="#" className={styles.covenantUrl} onClick={openCovenantUrl}>ブランディアBell特約 </a>
        <span>に同意します。</span>
      </div>

      <Button
        // IDはGTMでのトラッキング用に付与している
        id="btn__order--confirm"
        type="submit"
        className={`mt-5 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
        variant="light"
        disabled={submitDisabled}
      >
        予約内容を確認する
      </Button>

      <div className="text-center my-4">
        <Button
          className={styles.backBtn}
          type="button"
          variant="link"
          onClick={
            // 戻り先が無い場合はliffを閉じる
            history.action === 'POP'
              ? liff.closeWindow
              : history.goBack
          }
        >
          戻る
        </Button>
      </div>
    </Form>
  );
};

export default Component;
