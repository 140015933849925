import * as React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

import * as styles from './VerifyMobilePhoneSuccessPage.scss';

import iconCheck from '../../../assets/images/icon_check.png';

const Component: React.FC = () => {
  return (
    <Container>
      <Helmet>
        <title>SMS認証 | ブランディア オンライン店舗</title>
      </Helmet>
      <Row className="p-3 pb-4">
        <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
          <h4 className="mt-5 text-center">認証完了</h4>
          <div className="text-center">
            <p>査定申し込みへお進みください！</p>
          </div>
          <Row className="justify-content-center mt-5 mb-4">
            <Image src={iconCheck} className={`${styles.icon}`} />
          </Row>
          <Button
            type="button"
            className={`mt-5 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
            variant="light"
            onClick={liff.closeWindow}
          >
            査定申し込みへ進む
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Component;
