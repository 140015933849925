import * as React from 'react';
import {
  MAX_QTY_FOR_30_MINUTES_FRAME,
  MAX_QTY_FOR_60_MINUTES_FRAME,
  MAX_QTY_FOR_90_MINUTES_FRAME,
  MAX_QTY_FOR_120_MINUTES_FRAME
} from '../../../enums/SelectablePurchaseItemsQty';

import * as styles from './SelectedPurchaseItemEntry.scss';

type Props = {
  selectedQty: number;
}

const Component: React.FC<Props> = ({
  selectedQty
}) => {
  const getSelectedCourseName = (selectedQty:number) => {
    switch (selectedQty) {
      case MAX_QTY_FOR_30_MINUTES_FRAME:
        return `30分(1〜${MAX_QTY_FOR_30_MINUTES_FRAME}点)`
      case MAX_QTY_FOR_60_MINUTES_FRAME:
        return `60分(${MAX_QTY_FOR_30_MINUTES_FRAME + 1}〜${MAX_QTY_FOR_60_MINUTES_FRAME}点)`
      case MAX_QTY_FOR_90_MINUTES_FRAME:
        return `90分(${MAX_QTY_FOR_60_MINUTES_FRAME + 1}〜${MAX_QTY_FOR_90_MINUTES_FRAME}点)`
      case MAX_QTY_FOR_120_MINUTES_FRAME:
        return `120分(${MAX_QTY_FOR_90_MINUTES_FRAME + 1}〜${MAX_QTY_FOR_120_MINUTES_FRAME}点)`
      default:
        return;
    }
  }

  return (
    <div className={styles.purchaseItemEntry}>
      <div className="d-flex align-items-center">
        <p className={styles.entryLabel}>コース</p>
        <p className="mb-0">{getSelectedCourseName(selectedQty)}</p>
      </div>
    </div>
  );
};

export default Component;
