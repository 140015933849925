import { WorkableAttendant, AttendantGenderType, AttendantGender } from 'line-app/src/types/types';
import * as React from 'react';
import { Image } from 'react-bootstrap';
import AttendantDetailModal from '../../utilities/AttendantDetailModal/AttendantDetailModal';

import * as styles from './DesiredAttendant.scss';

type Props = {
  workableAttendant: WorkableAttendant
}

const Component: React.FC<Props> = ({
  workableAttendant
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const attendantImageUrl = workableAttendant.attendantImagePresignedUrl;

  // 性別の表示
  const displayAttendantGender = (gender: AttendantGenderType): JSX.Element => {
    switch (gender) {
      case AttendantGender.male:
        return <p className={`${styles.gender}`}>性別 / 男</p>
      case AttendantGender.female:
        return <p className={`${styles.gender}`}>性別 / 女</p>
      default:
        return <p></p>;
    }
  }

  return (
    <div className={`${styles.desiredAttendant} d-flex align-items-center`}>
      {attendantImageUrl && (
        <div className={`${styles.attendantImageWrap} d-flex justify-content-start`}>
          <Image className={styles.attendantImage} src={attendantImageUrl} />
        </div>
      )}
      <div className={styles.simpleAttendantDetails}>
        <div className={`${styles.attendantNameWrap}`}>
          <p>
            {workableAttendant.attendantName}
            <span> の予約可能日程</span>
          </p>
        </div>

        {displayAttendantGender(workableAttendant.gender)}

        <AttendantDetailModal
          showModal={showModal}
          setShowModal={setShowModal}
          workableAttendant={workableAttendant}/>
        <button className={styles.attendantDetailLink} onClick={() => setShowModal(true)}>この接客員の詳細</button>
      </div>
    </div>
  )
}

export default Component;
