import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';

// This file for getting scroll position.
import './scrollBase';

// Containers and
import PrivateRoute from './components/PrivateRoute';
import RootComponent from './components/pages/RootComponent/RootComponent';
import { LiffProvider } from './containers/LiffContainer';
import { UserServiceProvider } from './containers/UserServiceContainer';
import ScrollToTop from './components/ScrollToTop';
import RecordUsersAcquisitionChannel from './components/RecordUsersAcquisitionChannel';
import Loading from './components/pages/Loading/Loading';
import NotFound from './components/pages/NotFound/NotFound';

// page components
import VerifyMobilePhonePage from './components/pages/VerifyMobilePhonePage/VerifyMobilePhonePage';
import VerifyMobilePhoneSuccessPage from './components/pages/VerifyMobilePhoneSuccessPage/VerifyMobilePhoneSuccessPage';
import RegisterMobilePhonePage from './components/pages/RegisterMobilePhonePage/RegisterMobilePhonePage';
import SelectPurchasedItemPage from './components/pages/SelectPurchasedItemPage/SelectPurchasedItemPage';
import SelectPurchaseSchedulePage from './components/pages/SelectPurchaseSchedulePage/SelectPurchaseSchedulePage';
import PurchaseDetailPage from './components/pages/PurchaseDetailPage/PurchaseDetailPage';
import PurchaseCompleteCautionPage from './components/pages/PurchaseCompleteCautionPage/PurchaseCompleteCautionPage';
import ConfirmRealtimeReservation from './components/pages/ConfirmRealtimeReservation/ConfirmRealtimeReservation';
import ConfirmTelecubeReservation from './components/pages/ConfirmTelecubeReservation/ConfirmTelecubeReservation';

// GTM implementation
if (process.env.GTM_ID) {
  const tagManagerArgs = {
    gtmId: process.env.GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}

// entry point
const init = async () => {
  const liffId = process.env.LIFF_ID;

  // LIFF SDK初期化
  await liff.init({ liffId });

  // container類のwrapping（このあたり修正するたびにインデントで差分大きく出て大変なので各ページのrouteとは分ける）
  const ContainerWrapping = (children: React.ReactElement) => (
    <React.Suspense fallback={Loading}>
      <Helmet>
        <title>ブランディア オンライン店舗</title>
      </Helmet>
      <LiffProvider>
        <UserServiceProvider>{children}</UserServiceProvider>
      </LiffProvider>
    </React.Suspense>
  );

  // ContainerWrappingで包みながらroute定義
  const app = ContainerWrapping(
    <BrowserRouter basename="/liff/">
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={RootComponent} />
        {/* QRコード経由などで `/inlet` に遷移する想定。GETパラメータで流入元を計測するために存在するので、流入元を登録するためにAPIをinvokeした後LIFFを閉じる */}
        <PrivateRoute
          exact
          path="/inlet"
          component={RecordUsersAcquisitionChannel}
        />
        {/* アカウント関連 */}
        <PrivateRoute
          path="/accounts/new/mobile-phone/register"
          component={RegisterMobilePhonePage}
        />
        <PrivateRoute
          path="/accounts/new/mobile-phone/verify"
          component={VerifyMobilePhonePage}
        />
        <PrivateRoute
          path="/accounts/new/mobile-phone/verify-success"
          component={VerifyMobilePhoneSuccessPage}
        />
        <PrivateRoute
          path="/purchase-request/item/input"
          component={SelectPurchasedItemPage}
        />
        <PrivateRoute
          path="/purchase-forms/input"
          component={PurchaseDetailPage}
        />
        <PrivateRoute
          path="/purchase-request/complete"
          component={PurchaseCompleteCautionPage}
        />
        <PrivateRoute
          path="/purchase-request/realtime-reservation/confirm"
          component={ConfirmRealtimeReservation}
        />
        <PrivateRoute
          path="/purchase-schedule"
          component={SelectPurchaseSchedulePage}
        />
        <PrivateRoute
          path="/telecube-reservation/confirm"
          component={ConfirmTelecubeReservation}
        />
        {/* 404 */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );

  // React描画
  ReactDOM.render(app, document.getElementById('app'));
};

init();
