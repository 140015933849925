import * as React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useLiff } from '../containers/LiffContainer';
import { useUserService } from '../containers/UserServiceContainer';
import PleaseOpenByLine from './pages/PleaseOpenByLinePage/PleaseOpenByLine';

const Component: React.FC<RouteProps> = ({ ...props }) => {
  const {
    lineProfile,
    getLineProfile,
    getAccessToken,
    friendFlag,
    getFriendFlag,
    isInClient,
  } = useLiff();
  const { profile, getProfile } = useUserService();

  if (!isInClient() && process.env.NODE_ENV === 'production') {
    return <PleaseOpenByLine />;
  }

  // login状態を確認し、未loginであればログイン画面へ飛ばす(外部ブラウザの場合だけ。LINEで開いているのであれば常にログイン済み)。
  if (!liff.isLoggedIn()) {
    liff.login({
      redirectUri: `https://${process.env.HOST}/liff/?path=${location.pathname}`,
    });
    return <></>;
  }

  // friendFlagがnullだったら未取得なのでfetchする
  if (friendFlag === null) {
    throw getFriendFlag();
  }
  // friendFlag === falseの場合、友達登録がされていないので、友達登録画面に遷移
  if (!friendFlag) {
    alert('本サービスをご利用される場合は、次のアカウントの友だち追加またはブロック解除をお願いします。')
    // 友達追加を促すメッセージを出す必要がなければ、location.hrefを変更して友達追加画面に遷移
    location.href = `https://line.me/R/ti/p/${process.env.BOT_BASIC_ID}`;
    return <></>;
  }

  // LINEのprofileが未取得であれば取得
  if (!lineProfile) {
    throw getLineProfile();
  }

  // bellに登録しているProfileを取得
  if (typeof profile === 'undefined') {
    throw getProfile(getAccessToken());
  }

  return <Route {...props} />;
};

export default Component;
