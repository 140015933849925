import { useState, useRef } from 'react';
import { createContainer } from 'unstated-next';
import axios, { AxiosResponse } from 'axios';

const useLiffContainer = () => {
  const [lineProfile, setLineProfile] = useState<null | LineProfile>(null);
  const [friendFlag, setFriendFlag] = useState<null | boolean>(null);

  const profileFetchingRef = useRef<Promise<LineProfile> | null>(null);
  const friendShipFetchingRef = useRef<Promise<
    AxiosResponse<{ friendFlag: boolean }>
  > | null>(null);

  const getLineProfile = async (option?: { forceRefresh: boolean }) => {
    // forceRefreshフラグが渡されておらず、既に取得済みのprofileがある場合はそれを返却
    if (lineProfile?.userId && (!option || (option && !option.forceRefresh))) {
      return lineProfile;
    }

    // profile取得
    // 既に取得を始めていてPromiseがprofileFetchingRefに格納されていれば、その終了を待つ
    if (profileFetchingRef.current === null) {
      const getProfilePromise = liff.getProfile();
      profileFetchingRef.current = getProfilePromise;
    }

    const fetchedProfile = await profileFetchingRef.current;

    // requestが終わったらrefにnullを代入して、進行中のrequestがないことを表す
    profileFetchingRef.current = null;

    // cacheとして保存
    setLineProfile(fetchedProfile);
    // 返却
    return fetchedProfile;
  };

  const getFriendFlag = async (option?: {
    forceRefresh: boolean;
  }): Promise<boolean> => {
    if (friendFlag !== null && (!option || (option && !option.forceRefresh))) {
      return friendFlag;
    }

    // friendship取得リクエストを発行
    // 既に取得を始めていてPromiseがprofileFetchingRefに格納されていれば、その終了を待つ
    if (friendShipFetchingRef.current === null) {
      friendShipFetchingRef.current = axios.get<{ friendFlag: boolean }>(
        'https://api.line.me/friendship/v1/status',
        {
          headers: { Authorization: 'Bearer ' + liff.getAccessToken() },
        }
      );
    }

    const result = await friendShipFetchingRef.current;

    // requestが終わったらrefにnullを代入して、進行中のrequestがないことを表す
    friendShipFetchingRef.current = null;

    setFriendFlag(result.data.friendFlag);

    return result.data.friendFlag;
  };

  return {
    // useするcomponent側で使いやすいように as LineProfileして返却する
    // nullの可能性は `src/components/PrivateRoute.tsx` がReact.Suspense向けにPromiseをthrowして潰してくれる
    lineProfile: lineProfile as LineProfile,
    getLineProfile,
    getAccessToken: liff.getAccessToken,
    friendFlag: friendFlag as boolean,
    getFriendFlag,
    openWindow: liff.openWindow,
    closeWindow: liff.closeWindow,
    isInClient: liff.isInClient,
  };
};

const LiffContainer = createContainer(useLiffContainer);

export default LiffContainer;
export const LiffProvider = LiffContainer.Provider;
export const useLiff = LiffContainer.useContainer;
