import * as React from 'react';
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import axios from 'axios';

import { useLiff } from '../../../containers/LiffContainer';

import PurchaseItemForm from '../../molecules/SelectPurchaseItemForm/SelectPurchaseItemForm';
import PurchaseItemTextForm from '../../molecules/PurchaseItemTextForm/PurchaseItemTextForm';

import { SelectedItem } from '../../../types/types';

import * as styles from './SelectPurchasedItemPage.scss';

import stepOnlyOnePurchasedItem from '../../../assets/images/steps/onlyOnePurchasedItem.png';
import stepPurchasedItem from '../../../assets/images/steps/purchasedItem.png';

const Component: React.FC = () => {
  const { getAccessToken } = useLiff();

  const [selectedItems, setSelectedItems] = React.useState<SelectedItem[]>([]);

  // ローディング画面の制御用用
  const [isLoading, setIsLoading] = React.useState(true);

  // 子コンポーネント(PurchaseItemForm)の描画制御用
  const [finishedInit, setFinishedInit] = React.useState(false);

  // いまスグ査定のリクエストか
  const [isRealtime, setIsRealtime] = React.useState(false)

  // 選択されたいまスグ用online_purchase_schedule_id
  const [realtimeScheduleId, setRealtimeScheduleId] = React.useState<string | null>(null)

  //既存予約があればWindowを閉じる
  const closeWindowIfHasBookedSchedules = async () => {
    try {
      const res = await axios.get<boolean>(
        `${process.env.API_BASE_URL}/api/online-purchase-schedules/has-booked-schedules`,
        {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
          params: {}
        }
      );

      // 予約がある場合
      if (res.data) {
        liff.closeWindow();
      }
    } catch {
      alert('エラーが発生しました。再度ページをお開きいただくか、LINE上でお問い合わせください。');
      liff.closeWindow();
    }
  }

  const getSelectedItems = async (purchaseRequestId: string) => {
    await axios
      .get(
        `${process.env.API_BASE_URL}/api/purchase-request/${purchaseRequestId}/items`,
        {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
        }
      )
      .then(
        result => {
          setSelectedItems(result.data);
        },
        _reason => {
          alert('無効な操作です。メニューの「オンライン買取 予約する」から予約を開始してください。');
          liff.closeWindow();
        }
      );
  };

  React.useEffect(() => {
    const initItem = async () => {
      const params = qs.parse(location.search);

      // 既存予約ありの場合、liffを閉じる
      if (!params.addReserve){
        await closeWindowIfHasBookedSchedules();
      }

      // 進行中の予約があればその情報から持込予定品を補完
      const requestId = params.requestId as string | undefined
      if (requestId) {
        await getSelectedItems(requestId)
      }

      // いまスグの場合はスケジュール選択済なのでそのidをセット
      const isRealtimeParam = Boolean(params.isRealtime);
      if (isRealtimeParam) {
        setIsRealtime(true)
        setRealtimeScheduleId(params.scheduleId as string)
      }

      setFinishedInit(true); // 子コンポーネントの描画開始
      setTimeout(() => setIsLoading(false), 2000) // 子コンポーネントの描画終了までローディングさせる
    };

      initItem();
  }, []);

  return (
    <Container>
      <Helmet>
        <title>査定品情報入力</title>
      </Helmet>
      <Row className={`justify-content-center`}>
        <Image className={styles.headerStep} src={isRealtime ? stepOnlyOnePurchasedItem : stepPurchasedItem} rounded />
      </Row>
      {isLoading && (
        <div className={styles.screenOverlay}>
          <Spinner animation="border" variant="primary" />
        </div>
      )}

      {isRealtime && <h4 className={`mt-5 pl-3 text-left ${styles.boldText}`}>査定希望のお品物情報をご記載ください。</h4>}

      {/* いまスグ査定かでフォームを分ける */}
      {
        isRealtime && realtimeScheduleId
          ? <PurchaseItemTextForm
              selectedItems={selectedItems}
              scheduleId={realtimeScheduleId}
            />
          : finishedInit && (
            <Row className="p-3 pb-4">
              <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                <PurchaseItemForm
                  selectedItems={selectedItems}
                />
              </Col>
            </Row>
          )
      }
    </Container>
  );
};

export default Component;
