import { WorkableAttendant, AttendantGenderType, AttendantGender } from 'line-app/src/types/types';
import * as React from 'react';
import { Image } from 'react-bootstrap';

import * as styles from './AttendantDetailModal.scss';

type Props = {
  workableAttendant: WorkableAttendant;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Component: React.FC<Props> = ({
  workableAttendant,
  showModal,
  setShowModal,
}) => {
  const attendantImageUrl = workableAttendant.attendantImagePresignedUrl;

  // 性別の表示
  const displayAttendantGender = (gender:  AttendantGenderType): JSX.Element => {
    switch (gender) {
      case AttendantGender.male:
        return <p className={`${styles.gender}`}>性別 / 男</p>
      case AttendantGender.female:
        return <p className={`${styles.gender}`}>性別 / 女</p>
      default:
        return <p></p>;
    }
  }

  return (
    <div className={showModal ? '' : 'd-none'}>
      <div id={styles.overlay} onClick={() => setShowModal(false)} >
        <div
          className={styles.modalContent}
          onClick={(e) => e.stopPropagation()}>
          <p
            className={`${styles.modalCloseBtn}`}
            onClick={() => setShowModal(false)}>
              ×
          </p>

          <div className={`${styles.desiredAttendantModalDetails} d-flex justify-content-between align-items-center`}>
            {attendantImageUrl && (
              <div className={`${styles.attendantModalImageWrap} d-flex justify-content-start`}>
                <Image className={`${styles.attendantModalImage}`} src={attendantImageUrl} />
              </div>
            )}
            <div className={`${styles.simpleAttendantModalDetails} `}>
              <div className={`${styles.attendantNameWrap}`}>
                <p>{workableAttendant.attendantName}</p>
              </div>

              <div className={`${styles.genderAndFeeWrap} d-flex justify-content-center align-items-end`}>
                <div>
                  {displayAttendantGender(workableAttendant.gender)}
                </div>
                <div>
                  <p className={`${styles.nominationFee}`}>指名料 / ¥0</p>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.introductoryEssay}`}>
            <p>{workableAttendant.introductoryEssay}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Component;
