import * as React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import { useLiff } from '../../../containers/LiffContainer';
import { useUserService } from '../../../containers/UserServiceContainer';
import * as styles from './RegisterMobilePhonePage.scss';

export const registerMobilePhone = async (
  mobilePhone: string,
  accessToken: string
) => {
  const mobilePhoneWithoutHyphen =
    '+81' + mobilePhone.replace(/-/g, '').replace(/^0/, '');

  await axios.put(
    `${process.env.API_BASE_URL}/api/users/mobile-phone/register`,
    { mobilePhone: mobilePhoneWithoutHyphen },
    {
      headers: { Authorization: 'Bearer ' + accessToken },
    }
  );
};

const Component: React.FC = () => {
  const { getAccessToken } = useLiff();
  const { refreshProfile, profile } = useUserService();
  const history = useHistory();
  const { state } = useLocation<{ afterRegisterFlowCallback?: string }>();

  const [mobilePhone, setMobilePhone] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const defaultValues = {
    mobilePhone: '',
  };

  React.useEffect(() => {
    setIsLoading(true);

    axios
      .get(`${process.env.API_BASE_URL}/api/users/mobile-phone`, {
        headers: { Authorization: 'Bearer ' + getAccessToken() },
      })
      .then(
        result => {
          if (result.data.isMobilePhoneVerified) {
            alert('既に認証済みです');
            liff.closeWindow();
          } else if (
            result.data.isMobilePhoneVerified === false &&
            result.data.isCodeExpired === false
          ) {
            setIsLoading(false);
            let { mobilePhone } = profile;
            if (mobilePhone) {
              mobilePhone =
                '0' + mobilePhone.replace(/-/g, '').replace(/^\+81/, '');
            }

            history.push('/accounts/new/mobile-phone/verify', {
              mobilePhone,
            });
          } else {
            setIsLoading(false);
          }
        },
        reason => {
          setIsLoading(false);
          console.log(reason);
        }
      );
  }, []);

  const { register, handleSubmit, errors, setValue, formState } = useForm<
    typeof defaultValues
  >({
    mode: 'onBlur',
    defaultValues,
  });

  React.useEffect(() => {
    register(
      { name: 'mobilePhone' },
      {
        required: '必須です',
        pattern: {
          value: /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{4}[-\s\.]?[0-9]{4,6}/,
          message: '電話番号の形式が間違っています',
        },
      }
    );
  }, [register]);

  const onMobilePhoneChange = (e: any) => {
    const { value } = e.target;
    const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,4})(\d{0,4})/);
    const newValue = x[1] + (x[2] ? '-' + x[2] : '') + (x[3] ? '-' + x[3] : '');
    setValue('mobilePhone', newValue, true);
    setMobilePhone(newValue);
  };

  const onSubmit = async (data: typeof defaultValues): Promise<void> => {
    // isLoading === trueの時、既にAPIを叩いているので、その場合はそのままreturn
    if (isLoading) {
      return;
    }

    const { mobilePhone } = data;

    setIsLoading(true);

    try {
      await registerMobilePhone(mobilePhone, getAccessToken());
    } catch (err) {
      setIsLoading(false);
      if (err.response.status > 399 && err.response.status < 500) {
        alert('電話番号に誤りがあります。正しく電話番号を入力してください。');
      } else {
        alert('エラーが発生しました。');
      }
      return;
    }

    try {
      await refreshProfile(getAccessToken());

      setIsLoading(false);

      history.push('/accounts/new/mobile-phone/verify', {
        mobilePhone,
        afterRegisterFlowCallback: state?.afterRegisterFlowCallback,
      });
    } catch (err) {
      setIsLoading(false);

      console.error(err);
      alert('エラーが発生しました。');
    }
  };

  return (
    <Container>
      <Helmet>
        <title>SMS認証 | ブランディア オンライン店舗</title>
      </Helmet>
      {isLoading && (
        <div className={styles.screenOverlay}>
          <Spinner animation="border" variant="info" />
        </div>
      )}

      <Row className="p-3 pb-4">
        <Col md={{ span: 6, offset: 3 }}>
          <h4
            className={`mt-5 pb-3 text-center border-bottom ${styles.borderPrimary}`}
          >
            SMS認証情報入力
          </h4>
          <p className="mt-3 mb-4 text-center">
            ご本人確認のため、SMS認証が必要となります。携帯電話番号を入力し、
            認証コードを送信してください。
          </p>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="mobilePhone">
              <Form.Control
                className={`text-center ${styles.inputStyle}`}
                type="tel"
                name="mobilePhone"
                placeholder="090-0000-0000"
                value={mobilePhone}
                onChange={onMobilePhoneChange}
                isInvalid={formState.isSubmitted && !!errors.mobilePhone}
                isValid={formState.touched.mobilePhone && !errors.mobilePhone}
              />
              {formState.isSubmitted && errors.mobilePhone && (
                <Form.Text className="text-danger">
                  {errors.mobilePhone.message}
                </Form.Text>
              )}
            </Form.Group>
            <Button
              type="submit"
              className={`mt-4 mb-2 pb-3 pt-3 btn-block ${styles.button}`}
              variant="light"
              disabled={mobilePhone === ''}
            >
              認証コードを送信する
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Component;
