import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// import * as styles from './PleaseOpenByLine.scss';

const Component: React.FC = () => {
  return (
    <Container className="mt-3">
      <Row className="p-3 pb-4">
        <Col md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
          <h4 className="text-center text-muted p">
            ご利用にはLINEで本ページを開いて頂く必要があります。
          </h4>
        </Col>
      </Row>
    </Container>
  );
};

export default Component;
