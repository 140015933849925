import * as React from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Spinner,
} from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

import { useLiff } from '../../../containers/LiffContainer';
import { useUserService } from '../../../containers/UserServiceContainer';
import * as styles from './VerifyMobilePhonePage.scss';

import sms from '../../../assets/images/sms.svg';

import exclamation from '../../../assets/images/exclamation.svg';

import { registerMobilePhone } from '../RegisterMobilePhonePage/RegisterMobilePhonePage';

const Component: React.FC = () => {
  const { getAccessToken } = useLiff();
  const { refreshProfile } = useUserService();
  const history = useHistory();
  const { state } = useLocation<{
    mobilePhone: string;
    afterRegisterFlowCallback?: string;
  }>();

  const [verificationCode, setVerificationCode] = React.useState('');
  const [invokingVerificationApi, setInvokingVerificationApi] = React.useState(
    false
  );
  const [reRegisterMobile, setReRegisterMobile] = React.useState(false);

  const onVerificationCodeChange = (e: any) => {
    const { value } = e.target;
    const x = value.replace(/\D/g, '').match(/(\d{0,4})/);
    const newValue = x[1];
    setVerificationCode(newValue);
  };

  React.useEffect(() => {
    if (verificationCode.length === 4) {
      verifyCode();
    }
  }, [verificationCode]);

  const verifyCode = async () => {
    try {
      setInvokingVerificationApi(true);

      await axios.post(
        `${process.env.API_BASE_URL}/api/users/mobile-phone/verify`,
        { verificationCode: verificationCode },
        {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
        }
      );

      await refreshProfile(getAccessToken());

      setInvokingVerificationApi(false);

      history.push('/accounts/new/mobile-phone/verify-success', {
        afterRegisterFlowCallback: state?.afterRegisterFlowCallback,
      });
    } catch (err) {
      setInvokingVerificationApi(false);

      // @ts-ignore
      const errorTxt = err?.response?.data?.message || 'エラーが発生しました。'
      alert(errorTxt);
    }
  };

  return (
    <Container>
      <Helmet>
        <title>SMS認証 | ブランディア オンライン店舗</title>
      </Helmet>
      <Row className="p-3 pb-4">
        <Col md={{ span: 6, offset: 3 }}>
          <div className="mt-5">
            <Row className="justify-content-center mb-3">
              <Image src={sms} className={styles.smsImage} rounded />
            </Row>
            <div className="text-center">
              <p>
                {state?.mobilePhone}に
                <br />
                認証コードを送信しました。
                <br />
                受け取ったコードを入力してください。
              </p>
            </div>
          </div>

          <Form.Group controlId="verifyCode">
            <Form.Control
              className={`text-center ${styles.inputStyle}`}
              type="text"
              name="verifyCode"
              placeholder="_ _ _ _"
              value={verificationCode}
              onChange={onVerificationCodeChange}
              disabled={invokingVerificationApi}
            />
            {invokingVerificationApi && (
              <div className={styles.spinnerContainer}>
                <Spinner animation="border" variant="info" />
              </div>
            )}
          </Form.Group>
          <Button
            type="button"
            className={`mt-4 mb-2 pb-3 pt-3 btn-block ${styles.buttonOutline}`}
            variant="light"
            disabled={reRegisterMobile}
            onClick={() => {
              setReRegisterMobile(true);
              registerMobilePhone(state?.mobilePhone, getAccessToken());
            }}
          >
            認証コードを再送信する
          </Button>
          <br />
          <div className="text-left">
            <p className={styles.textSoftRed}>
              <Image src={exclamation} />迷惑SMSブロック設定などにより、SMSが届かない場合がございます。SMSが届かない場合、設定をご確認の上、再度お試しください。<br/>
              設定後もSMSが届かない場合は、<a href="mailto:buyer@brandear.jp?subject=SMSの認証コードが届かない&body=※件名は変更せずに送信してください※%0D%0A%0D%0A認証コードが届かない電話番号を教えてください。%0D%0A【電話番号:&emsp;&emsp;&emsp;&emsp;&emsp;】%0D%0A%0D%0A担当者が確認いたしまして折り返しメール連絡をさせて頂きます。%0D%0Aご不便をお掛けしておりますがご連絡まで今しばらくお待ちくださいませ。">こちら</a>からお問い合わせください。
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Component;
