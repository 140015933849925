import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import axios from 'axios';

import * as styles from './ConfirmRealtimeReservation.scss';
import { useLiff } from '../../../containers/LiffContainer';

const Component: FC = () => {
  const { getAccessToken } = useLiff()
  const history = useHistory()
  const locationState = useLocation<{ remarks?: string | null }>()

  const [buttonDisabled, setButtonDisabled] = React.useState(false)
  const [realTimePurchaseScheduleId, setRealTimePurchaseScheduleId] = React.useState<string>("")

  const params = qs.parse(location.search)

  /**
   * 予約変更初期処理として仮予約情報を作成or更新し、変更前買取持込予定品をコピー
   * @param scheduleId
   * @param beforeRequestId
   */
  const postPurchaseRequestAndCopyItems = async (scheduleId: string, beforeRequestId: string) => {
    try {
      await axios.post(
        `${process.env.API_BASE_URL}/api/purchase-request/copy-item`,
        {
          scheduleId,
          beforeRequestId,
        },
        { headers: { Authorization: 'Bearer ' + getAccessToken() } }
      );
    } catch {
      alert('エラーが発生しました。お手数ですが、最初からやり直してください。');
    } finally {
      liff.closeWindow();
    }
  }

  /**
   * 仮予約情報を作成or更新
   * @param scheduleId
   * @param remarks
   */
  const createOrUpdateTmpPurchaseRequest = async (
    scheduleId: string,
    remarks: string | null[]
  ) => {
    try {
      await axios.put(
        `${process.env.API_BASE_URL}/api/purchase-request`,
        {
          scheduleId,
        },
        { headers: { Authorization: 'Bearer ' + getAccessToken() } }
      );

      await axios.put(
        `${process.env.API_BASE_URL}/api/purchase-request/item`,
        {
          remarks,
        },
        {
          headers: { Authorization: 'Bearer ' + getAccessToken() },
        },
      );
    } catch (err) {
      alert('エラーが発生しました。お手数ですが、最初からやり直してください。');
    } finally {
      liff.closeWindow();
    }
  }

  /**
   * いまスグ査定準備中の旨をSlack通知
   * @param scheduleId
   */
  const notifySlackPreparationForRealtimeAppraise = (scheduleId: string) => {
    try {
      axios
        .post(
          `${process.env.API_BASE_URL}/api/slack-notification/prepare-realtime-appraise`,
          {
            scheduleId,
          },
          { headers: { Authorization: 'Bearer ' + getAccessToken() } }
        )
    } catch (err) {
      alert('エラーが発生しました。時間を置いて再度お試しください。')
      setButtonDisabled(false)
    }
  }

    //いまスグ枠の取得
  const getRealTimePurchaseScheduleId = async () => {
    const res = await axios.get<string>(
      `${process.env.API_BASE_URL}/api/online-purchase-schedules/realtime-purchase-schedule-id`,
      {
        headers: { Authorization: 'Bearer ' + getAccessToken() },
      }
    )

    // 過去に送信されたいますぐのイメージマップ画像が押された時、予約可能枠がなかったらアラートしLIFFを閉じる
    if (!res.data) {
      alert('ただいま、いますぐ予約可能な予約枠が存在しません。\nメニューの「査定する」から再度の予約手続きをお願いします。')
      liff.closeWindow()
    }

    setRealTimePurchaseScheduleId(res.data)
  }

  /**
   * @param scheduleId
   */
  const onClick = async (scheduleId: string) => {
    setButtonDisabled(true)
    const beforeRequestId = params.beforeRequestId as string | undefined;

    notifySlackPreparationForRealtimeAppraise(scheduleId)

    // 予約変更フローのいまスグ選択の場合もデータ更新してLIFFを閉じる
    if (beforeRequestId) {
      return await postPurchaseRequestAndCopyItems(scheduleId, beforeRequestId)
    }

    const remarks = locationState.state?.remarks

    // 既に持ち込み予定品入力済であれば予約データを反映してLIFFを閉じる
    if (remarks) {
      return await createOrUpdateTmpPurchaseRequest(scheduleId, remarks)
    }

    // 持ち込み予定品入力ページへ遷移
    history.push(`/purchase-request/item/input?scheduleId=${scheduleId}&isRealtime=1`)
  }

  React.useEffect(() => {
    ;(async () => {
      try {
        await getRealTimePurchaseScheduleId()
      } catch {
        alert('エラーが発生しました。再度ページをお開きいただくか、LINE上でお問い合わせください。')
        liff.closeWindow()
      }
    })()
  }, []);

  return (
    <Container>
      <Helmet>
        <title>手続き前確認</title>
      </Helmet>

      <div className={styles.wrap}>
        {realTimePurchaseScheduleId &&
          <>
            <div>
              <p>お品物情報入力後</p>
              <p>すぐに査定を開始できます</p>
              <p>手続きを進めますか？</p>
            </div>

            <div className={styles.buttons}>
              <Button
                className={`pb-3 pt-3 ${styles.yesBtn}`}
                type="button"
                variant="light"
                onClick={() => onClick(realTimePurchaseScheduleId)}
                disabled={buttonDisabled}
              >
                次へ進む
              </Button>
              <Button
                className={`${styles.backBtn} mt-5`}
                type="button"
                variant="link"
                disabled={buttonDisabled}
                onClick={
                  // LINEメッセージ上から遷移した場合は戻り先が無いのでliffを閉じる
                  history.action === 'POP'
                    ? liff.closeWindow
                    : history.goBack
                }
              >
                戻る
              </Button>
            </div>
          </>
        }
      </div>
    </Container>
  );
};

export default Component;
