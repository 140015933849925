import { useState, useRef } from 'react';
import { createContainer } from 'unstated-next';
import axios, { AxiosResponse } from 'axios';

// TODO: typing user's profile and export this.
type UserProfile = {
  userId: number;
  lineId: string;
  userName?: string;
  mobilePhone?: string;
  isMobilePhoneVerified: number;
};

const useUserServiceContainer = () => {
  // ユーザーのprofileを保持するstate
  // undefined --> 初期状態
  // null --> user_infos未登録
  // UserProfile --> 登録済み、正しく取得ができている
  const [profile, setProfile] = useState<undefined | null | UserProfile>(
    undefined
  );

  const userProfileFetchingRef = useRef<Promise<
    AxiosResponse<UserProfile>
  > | null>(null);

  /**
   *
   * @param accessToken
   * @param option
   */
  const getProfile = async (
    accessToken: string,
    option?: { forceRefresh: boolean }
  ) => {
    // forceRefreshフラグが渡されておらず、既に取得済みのprofileがある場合はそれを返却
    if (profile?.userId && (!option || (option && !option.forceRefresh))) {
      return profile;
    }

    // profile取得
    // 既に取得を始めていてPromiseがprofileFetchingRefに格納されていれば、その終了を待つ
    try {
      if (userProfileFetchingRef.current === null) {
        userProfileFetchingRef.current = axios.get<UserProfile>(
          `${process.env.API_BASE_URL}/api/users/account`,
          {
            headers: { Authorization: 'Bearer ' + accessToken },
          }
        );
      }

      const profileFetchResult = await userProfileFetchingRef.current;

      // cacheとして保存
      setProfile(profileFetchResult.data);

      // 返却
      return profileFetchResult.data;
    } catch (err) {
      // エラー返却はuser_infos未作成の場合
      setProfile(null);
      return null;
    } finally {
      // エラーにしろ成功にしろ、requestが終わったらrefにnullを代入して、進行中のrequestがないことを表す
      userProfileFetchingRef.current = null;
    }
  };

  const refreshProfile = async (accessToken: string) => {
    return await getProfile(accessToken, { forceRefresh: true });
  };

  const updateProfile = async (
    newProfile: UserProfile,
    accessToken: string
  ) => {
    return await axios.put(
      `${process.env.API_BASE_URL}/api/users`,
      { ...newProfile },
      {
        headers: { Authorization: 'Bearer ' + accessToken },
      }
    );
  };

  return {
    // useするcomponent側で使いやすいように as UserProfileして返却する
    // nullの可能性は `src/components/PrivateRoute.tsx` がReact.Suspense向けにPromiseをthrowして潰してくれる
    profile: profile as UserProfile,
    getProfile,
    refreshProfile,
    updateProfile,
  };
};

const UserServiceContainer = createContainer(useUserServiceContainer);

export default UserServiceContainer;
export const UserServiceProvider = UserServiceContainer.Provider;
export const useUserService = UserServiceContainer.useContainer;
